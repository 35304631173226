// src/components/Confirmation.js

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { clearCart } from "../../state";
import { fetcher2 } from "../../api";
import jsPDF from "jspdf";
import "jspdf-autotable";

const FREE_ITEMS_START_DATE = new Date(
  process.env.REACT_APP_FREE_ITEMS_START_DATE
);

const Confirmation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [freeItemsAdded, setFreeItemsAdded] = useState(false);
  const [webshopOrder, setWebshopOrder] = useState(null);
  const { webshopOrderNumber } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const iframeRef = useRef(null); // Ref voor het iframe

  // State om te bepalen of we een printprompt moeten tonen (voor print=true)
  const [showPrintPrompt, setShowPrintPrompt] = useState(false);

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const shouldPrint = queryParams.get("print") === "true";

  // Check the paymentStatus and display the appropriate message
  let paymentMessage = null;

  if (webshopOrder && webshopOrder.attributes.paymentStatus === "open") {
    paymentMessage = (
      <Alert severity="warning">
        <AlertTitle>Betaling in afwachting</AlertTitle>
        Je betaling is nog niet voltooid. Controleer je betaling of neem contact
        met ons op als je hulp nodig hebt.
      </Alert>
    );
  } else if (
    webshopOrder &&
    webshopOrder.attributes.paymentStatus === "failed"
  ) {
    paymentMessage = (
      <Alert severity="error">
        <AlertTitle>Betaling Mislukt</AlertTitle>
        Helaas is je betaling mislukt. Probeer opnieuw te betalen of neem
        contact met ons op.
      </Alert>
    );
  } else if (webshopOrder && webshopOrder.attributes.paymentStatus === "paid") {
    paymentMessage = (
      <Alert severity="success">
        <AlertTitle>Gelukt!</AlertTitle>
        Je bestelling is succesvol ontvangen, dankjewel! —
        <strong>Je kunt hieronder je afhaalbewijs afdrukken.</strong>
      </Alert>
    );
  } else if (
    webshopOrder &&
    webshopOrder.attributes.paymentStatus === "completed"
  ) {
    paymentMessage = (
      <Alert severity="success">
        <AlertTitle>Gelukt!</AlertTitle>
        Je bestelling is succesvol ontvangen, dankjewel! —
        <strong>Je kunt hieronder je afhaalbewijs afdrukken.</strong>
      </Alert>
    );
  } else if (
    webshopOrder &&
    webshopOrder.attributes.paymentStatus === "contant"
  ) {
    paymentMessage = (
      <Alert severity="info">
        <AlertTitle>LET OP!</AlertTitle>
        Je hebt gekozen voor betaling in de winkel (contant of pin). Dit dient
        nog te gebeuren in de winkel voordat je de bestelling mee krijgt.
      </Alert>
    );
  }
  // Nieuwe condities voor 'cancelled' en 'refunded'
  else if (
    webshopOrder &&
    (webshopOrder.attributes.paymentStatus === "cancelled" ||
      webshopOrder.attributes.paymentStatus === "refunded")
  ) {
    paymentMessage = (
      <Alert severity="error">
        <AlertTitle>Bestelling Geannuleerd</AlertTitle>
        Je bestelling is geannuleerd en kan niet meer worden opgehaald. Neem
        contact met ons op voor verdere vragen of ondersteuning.
      </Alert>
    );
  }

  useEffect(() => {
    if (webshopOrderNumber && webshopOrderNumber.startsWith("cs_")) {
      // Construeer de nieuwe URL met behoud van bestaande queryparameters
      const searchParams = new URLSearchParams(location.search);
      const queryString = searchParams.toString();
      const redirectPath = `/checkout/success2/${webshopOrderNumber}${
        queryString ? `?${queryString}` : ""
      }`;
      navigate(redirectPath);
    }
  }, [webshopOrderNumber, navigate, location.search]);

  const fetchFreeItems = async () => {
    try {
      const data = await fetcher2("free-items");
      return data.data.map((item) => item.attributes);
    } catch (error) {
      console.error("Error fetching free items:", error);
    }
  };

  const determineFreeItems = (totalPrice, freeItems, addConditionalItems) => {
    const eligibleFreeItems = freeItems.filter(
      (item) => totalPrice >= item.freeFromPrice
    );
    eligibleFreeItems.sort((a, b) => b.freeFromPrice - a.freeFromPrice);
    const alwaysFreeItems = freeItems.filter(
      (item) => item.freeFromPrice === 0
    );
    let itemsToAdd = [...alwaysFreeItems];
    if (addConditionalItems && eligibleFreeItems.length > 0) {
      const highestEligibleItem = eligibleFreeItems[0];
      itemsToAdd = [...itemsToAdd, highestEligibleItem];
    }
    return itemsToAdd.filter(Boolean);
  };

  const calculateEligibleTotalPrice = (products) => {
    return products
      .filter((product) => product.countFreeFireworks)
      .reduce((total, product) => total + product.price * product.count, 0);
  };

  useEffect(() => {
    fetcher2(
      `webshop-orders?filters[webshopOrderNumber][$eq]=${webshopOrderNumber}`
    )
      .then((data) => {
        const order = data.data[0];
        if (!order || freeItemsAdded) return;

        const orderDate = new Date(order.attributes.createdAt);

        fetchFreeItems().then((freeItems) => {
          const eligibleTotalPrice = calculateEligibleTotalPrice(
            order.attributes.products
          );
          const addConditionalItems = orderDate >= FREE_ITEMS_START_DATE;
          const freeProductsToAdd = determineFreeItems(
            eligibleTotalPrice,
            freeItems,
            addConditionalItems
          ).map((item) => ({
            ...item,
            price: 0,
            count: 1,
          }));

          if (freeProductsToAdd.length > 0) {
            const updatedOrder = {
              ...order,
              attributes: {
                ...order.attributes,
                products: [...order.attributes.products, ...freeProductsToAdd],
              },
            };
            setWebshopOrder(updatedOrder);
            setFreeItemsAdded(true);
          } else {
            setWebshopOrder(order);
          }
        });
      })
      .catch((err) => console.error(err));
  }, [webshopOrderNumber, freeItemsAdded]);

  useEffect(() => {
    if (!webshopOrder) return;
    dispatch(clearCart());
  }, [webshopOrder, dispatch]);

  // Functie om de PDF te genereren met vaste A4 grootte en consistente tekstgroottes
  const generatePDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    let yPosition = 10;

    // Stel lettergroottes in
    const titleFontSize = 18;
    const headerFontSize = 12;
    const textFontSize = 10;

    // Titel
    pdf.setFontSize(titleFontSize);
    pdf.text("VUURWERK BRUCHEM", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 10;

    // Contactinformatie
    pdf.setFontSize(textFontSize);
    pdf.text("Dorpsstraat 20", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
    pdf.text("5314 AE Bruchem", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
    pdf.text("0418 700 511", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
    pdf.text("VUURWERKBRUCHEM.NL", pageWidth / 2, yPosition, {
      align: "center",
    });
    yPosition += 10;

    pdf.line(10, yPosition, pageWidth - 10, yPosition);
    yPosition += 10;

    // Bestelgegevens
    pdf.setFontSize(headerFontSize);
    pdf.text("Bestelgegevens", 10, yPosition);
    yPosition += 8;

    pdf.setFontSize(textFontSize);
    pdf.text(`Bestelnummer: ${webshopOrder.id}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Naam: ${webshopOrder.attributes.userName}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Email: ${webshopOrder.attributes.email}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Telefoon: ${webshopOrder.attributes.phoneNumber}`, 10, yPosition);
    yPosition += 10;

    // Afhaalgegevens
    pdf.setFontSize(headerFontSize);
    pdf.text("Afhaalgegevens", 10, yPosition);
    yPosition += 8;

    pdf.setFontSize(textFontSize);
    pdf.text(`Datum: ${webshopOrder.attributes.pickupDate}`, 10, yPosition);
    yPosition += 6;
    pdf.text(`Tijd: ${webshopOrder.attributes.pickupTime}`, 10, yPosition);
    yPosition += 6;
    pdf.text(
      `Status: ${
        webshopOrder.attributes.paymentStatus === "paid" ||
        webshopOrder.attributes.paymentStatus === "completed"
          ? "Betaald"
          : webshopOrder.attributes.paymentStatus === "contant"
          ? "Contant, nog te betalen bij afhalen via pin of contant"
          : webshopOrder.attributes.paymentStatus === "open"
          ? "Nog Niet Afgerond, deze bestelling is nog niet afgerond, we hebben geen betaling ontvangen."
          : webshopOrder.attributes.paymentStatus === "cancelled"
          ? "Geannuleerd, deze bestelling is geannuleerd en kan niet opgehaald worden."
          : webshopOrder.attributes.paymentStatus === "refunded"
          ? "Terugbetaald, deze bestelling is terugbetaald en kan niet opgehaald worden."
          : webshopOrder.attributes.paymentStatus
      }`,
      10,
      yPosition
    );

    yPosition += 10;

    // Producten Tabel
    pdf.setFontSize(headerFontSize);
    pdf.text("Producten", 10, yPosition);
    yPosition += 8;

    const tableColumn = ["Art. Nummer", "Naam", "Prijs", "Aantal", "Subtotaal"];
    const tableRows = [];

    webshopOrder.attributes.products.forEach((product) => {
      const rowData = [
        product.artNumber || "",
        product.name || "",
        `€${product.price ? product.price.toFixed(2) : "0.00"}`,
        product.count.toString(),
        `€${(product.price * product.count).toFixed(2)}`,
      ];
      tableRows.push(rowData);
    });

    pdf.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: yPosition,
      theme: "grid",
      styles: {
        fontSize: textFontSize,
      },
      headStyles: {
        fillColor: [200, 200, 200],
      },
      margin: { left: 10, right: 10 },
    });

    yPosition = pdf.autoTable.previous.finalY + 10;

    // Totals
    pdf.setFontSize(textFontSize);
    pdf.text(`Subtotaal: €${priceBeforeDiscount.toFixed(2)}`, 10, yPosition);
    yPosition += 6;

    if (priceBeforeDiscount > subtotalAfterDiscount) {
      pdf.text(
        `Subtotaal na korting: €${subtotalAfterDiscount.toFixed(2)}`,
        10,
        yPosition
      );
      yPosition += 6;
    }

    if (transactionFee > 0) {
      pdf.text(
        `Transactiekosten: €${transactionFee.toFixed(2)}`,
        10,
        yPosition
      );
      yPosition += 6;
    }

    pdf.text(`Totaal: €${totalPrice.toFixed(2)}`, 10, yPosition);
    yPosition += 10;

    // Footer Message
    pdf.setFontSize(textFontSize);
    pdf.text(
      "Dank voor uw bestelling bij Vuurwerk Bruchem!",
      pageWidth / 2,
      yPosition,
      { align: "center" }
    );

    // Paginanummers
    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.text(
        `Pagina ${i} van ${pageCount}`,
        pageWidth / 2,
        pdf.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }

    return pdf;
  };

  // Functie om het printen te hanteren
  const handlePrint = () => {
    const pdf = generatePDF();
    const blobURL = pdf.output("bloburl");

    if (isMobile) {
      // Voor mobiele apparaten, open een nieuw venster direct
      const newWindow = window.open("", "_blank");

      if (newWindow) {
        // Zet een laadindicator of een bericht in het nieuwe venster
        newWindow.document.write("<p>De PDF wordt geladen...</p>");

        // Wacht even om ervoor te zorgen dat het nieuwe venster is geopend
        setTimeout(() => {
          newWindow.location.href = blobURL;

          // Optioneel: Voeg een event listener toe om printen te activeren zodra de PDF is geladen
          newWindow.onload = () => {
            newWindow.focus();
            newWindow.print();
          };
        }, 100);
      } else {
        // Als het nieuwe venster niet geopend kan worden, geef een alert met een directe link
        alert(
          "Het openen van de PDF is geblokkeerd. Klik hier om de PDF te openen: " +
            blobURL
        );
      }
    } else {
      // Voor desktop apparaten, gebruik de iframe methode
      if (iframeRef.current) {
        iframeRef.current.src = blobURL;

        // Wacht tot het iframe geladen is en activeer de print dialoog
        iframeRef.current.onload = () => {
          iframeRef.current.contentWindow.focus();
          iframeRef.current.contentWindow.print();
        };
      }
    }
  };

  // useEffect om te controleren op print=true en het tonen van een print prompt
  useEffect(() => {
    if (shouldPrint && webshopOrder) {
      if (isMobile) {
        // Op mobiele apparaten tonen we een knop voor de gebruiker om te klikken
        setShowPrintPrompt(true);
      } else {
        // Op desktop apparaten printen we automatisch
        handlePrint();
      }
    }
  }, [shouldPrint, webshopOrder, isMobile]);

  if (!webshopOrder) {
    return <div>Loading...</div>;
  }

  // Get prices from the order
  const totalPrice = webshopOrder.attributes.totalPrice || 0;
  const transactionFee = webshopOrder.attributes.transactionFee || 0;

  // Calculate subtotal after discount (excluding transaction fees)
  const subtotalAfterDiscount = totalPrice - transactionFee;

  // Get priceBeforeDiscount or calculate it if not available
  let priceBeforeDiscount = webshopOrder.attributes.priceBeforeDiscount;
  if (priceBeforeDiscount === undefined || priceBeforeDiscount === null) {
    // Calculate priceBeforeDiscount based on original prices
    priceBeforeDiscount = 0;
    webshopOrder.attributes.products.forEach((product) => {
      const originalPrice = product.originalPrice || product.price;
      priceBeforeDiscount += originalPrice * product.count;
    });
  }

  return (
    <Box
      className="shift-on-search"
      m="60px auto"
      width={isMobile ? "95%" : "80%"}
      height="100%"
      p={isMobile ? 2 : 3}
    >
      {/* Embed het PDF iframe (zichtbaar of verborgen) */}
      <iframe
        ref={iframeRef}
        style={{ display: "none" }}
        title="Afhaalbewijs PDF"
      ></iframe>

      {/* Display the appropriate payment status message */}
      {paymentMessage}

      {/* Display content for status paid/completed/contant */}
      {webshopOrder && (
        <>
          {/* On-screen content */}
          <Box
            id="printArea"
            m="20px auto"
            p={isMobile ? 2 : 3}
            sx={{
              width: "100%",
            }}
          >
            <Box display="flex" justifyContent="flex-start">
              <Typography
                variant={isMobile ? "h5" : "h3"}
                component="h3"
                gutterBottom
                className="vuurwerk-bruchem-text"
              >
                <b>VUURWERK BRUCHEM</b>
              </Typography>
            </Box>

            <Typography align="left" gutterBottom>
              Dorpsstraat 20
            </Typography>
            <Typography align="left" gutterBottom>
              5314 AE Bruchem
            </Typography>
            <Typography align="left" gutterBottom>
              0418 700 511
            </Typography>
            <Typography align="left" gutterBottom>
              VUURWERKBRUCHEM.NL
            </Typography>

            <hr style={{ margin: "20px 0" }} />

            <Box my={3}>
              <Typography>
                <strong>Bestelnummer:</strong> {webshopOrder.id}
              </Typography>
              <Typography>
                <strong>Naam:</strong> {webshopOrder.attributes.userName}
              </Typography>
              <Typography>
                <strong>Email:</strong> {webshopOrder.attributes.email}
              </Typography>
              <Typography>
                <strong>Telefoon:</strong> {webshopOrder.attributes.phoneNumber}
              </Typography>
            </Box>

            <Typography variant="h6" gutterBottom>
              Afhaalgegevens
            </Typography>

            <Typography>
              <strong>Datum:</strong> {webshopOrder.attributes.pickupDate}
            </Typography>
            <Typography>
              <strong>Tijd:</strong> {webshopOrder.attributes.pickupTime}
            </Typography>
            <Typography>
              <strong>Status:</strong>{" "}
              {webshopOrder.attributes.paymentStatus === "paid" ||
              webshopOrder.attributes.paymentStatus === "completed"
                ? "Betaald"
                : webshopOrder.attributes.paymentStatus === "contant"
                ? "Contant, nog te betalen bij afhalen via pin of contant"
                : webshopOrder.attributes.paymentStatus === "open"
                ? "Nog Niet Afgerond, we hebben geen betaling ontvangen."
                : webshopOrder.attributes.paymentStatus === "cancelled"
                ? "Geannuleerd, deze bestelling is geannuleerd en kan niet opgehaald worden."
                : webshopOrder.attributes.paymentStatus === "refunded"
                ? "Terugbetaald, deze bestelling is terugbetaald en kan niet opgehaald worden."
                : webshopOrder.attributes.paymentStatus}
            </Typography>

            <Box mt={3}>
              <Typography variant="h6" gutterBottom>
                Producten
              </Typography>

              <Box overflowX="auto">
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                        Art. Nummer
                      </th>
                      <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                        Naam
                      </th>
                      <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                        Prijs
                      </th>
                      <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                        Aantal
                      </th>
                      <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                        Subtotaal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {webshopOrder.attributes.products.map((product, index) => (
                      <tr key={index}>
                        <td style={{ paddingBottom: "8px" }}>
                          {product.artNumber}
                        </td>
                        <td style={{ paddingBottom: "8px" }}>{product.name}</td>
                        <td style={{ paddingBottom: "8px" }}>
                          €{product.price ? product.price.toFixed(2) : "0.00"}
                        </td>
                        <td style={{ paddingBottom: "8px" }}>
                          {product.count}
                        </td>
                        <td style={{ paddingBottom: "8px" }}>
                          €{(product.price * product.count).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    {/* Subtotaal (voor korting) */}
                    <tr>
                      <td colSpan="4" style={{ paddingTop: "10px" }}>
                        <strong>Subtotaal:</strong>
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        <strong>€{priceBeforeDiscount.toFixed(2)}</strong>
                      </td>
                    </tr>

                    {/* Subtotaal na toegepaste korting */}
                    {priceBeforeDiscount > subtotalAfterDiscount && (
                      <tr>
                        <td colSpan="4" style={{ paddingTop: "10px" }}>
                          <strong>Subtotaal na toegepaste korting:</strong>
                        </td>
                        <td style={{ paddingTop: "10px" }}>
                          <strong>€{subtotalAfterDiscount.toFixed(2)}</strong>
                        </td>
                      </tr>
                    )}

                    {/* Transactiekosten */}
                    {transactionFee > 0 && (
                      <tr>
                        <td colSpan="4" style={{ paddingTop: "10px" }}>
                          <strong>Transactiekosten:</strong>
                        </td>
                        <td style={{ paddingTop: "10px" }}>
                          <strong>€{transactionFee.toFixed(2)}</strong>
                        </td>
                      </tr>
                    )}

                    {/* Totaal */}
                    <tr>
                      <td colSpan="4" style={{ paddingTop: "10px" }}>
                        <strong>Totaal:</strong>
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        <strong>€{totalPrice.toFixed(2)}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Box>
            </Box>

            <Typography align="center" gutterBottom>
              Dank voor uw bestelling bij Vuurwerk Bruchem!
            </Typography>
          </Box>

          {webshopOrder && (
            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                fullWidth={isMobile}
              >
                Print Afhaalbewijs
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Confirmation;
